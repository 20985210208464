import React from 'react';

import './footer.scss';

const Footer = (props) => {
  return (
    <footer>
      Designed &amp; Built by Leo Lee
    </footer>
  )
}

export default Footer;